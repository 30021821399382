import { ValidRole } from 'lib/auth'
import { routes } from 'lib/routes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAuth } from './useAuth'

export type AuthGuardProps = {
  allowedRoles?: ValidRole[]
}

export const useAuthGuard = ({ allowedRoles }: AuthGuardProps): boolean => {
  const { userHasRole } = useAuth()
  const { asPath, replace } = useRouter()
  const hasRole = userHasRole(allowedRoles)

  useEffect(() => {
    if (allowedRoles && allowedRoles.length > 0 && !hasRole) {
      const path = routes.accessDenied.href()
      const returnToQuery = asPath !== path ? `?returnTo=${asPath}` : ''
      const url = `${path}${returnToQuery}`
      // TODO implement access denied return URL for auth0
      void replace(url)
    }
  }, [replace, allowedRoles, asPath, hasRole])

  if (allowedRoles && allowedRoles.length > 0 && !hasRole) {
    return false
  }
  return true
}
