import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import { AuthGuardProps, useAuthGuard } from 'hooks/useAuthGuard'
import React from 'react'

export const AuthGuard = ({
  children,
  allowedRoles,
}: React.PropsWithChildren<AuthGuardProps>) => {
  const hasRole = useAuthGuard({ allowedRoles })

  if (!hasRole) {
    return <SplashScreen />
  }

  return <>{children}</>
}

// You should only wrap a regular react component with this HOC
// Not a page component with getInitialProps function as this HOC won't call it
export const WithAuthGuard = (
  WrappedComponent: React.ComponentType<any>,
  { allowedRoles }: AuthGuardProps,
) => {
  const WithAuthGuardWrapper = (props: any) => {
    const hasRole = useAuthGuard({ allowedRoles })
    if (!hasRole) {
      return <SplashScreen />
    }
    return <WrappedComponent {...props} />
  }

  return WithAuthGuardWrapper
}
