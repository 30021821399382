import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import { useAuth } from 'hooks/useAuth'
import { assertUnreachable } from 'lib/assertUnreachable'
import { useVenuesSelectQuery } from 'lib/gql'
import { routes } from 'lib/routes'
import React, { useEffect } from 'react'

export const LandingRedirect = () => {
  const { user } = useAuth()
  const [{ data, fetching }] = useVenuesSelectQuery()
  const venueId = data?.venuesSelect[0]?.id
  const venueCount = data?.venuesSelect.length
  const userHasNoVenue = venueCount !== undefined && venueCount === 0
  const readyToRedirect = !fetching && !!data && !!venueId
  const role = user.role

  useEffect(() => {
    if (userHasNoVenue) {
      void navigate(routes.noVenueFound)
      return
    }

    if (!readyToRedirect || !role) {
      return
    }

    switch (role) {
      case 'admin':
        void navigate(routes.venues)
        break
      case 'owner':
      case 'venue_staff':
        void navigate(routes.salesReport, { venueId })
        break
      case 'menu_builder':
      case 'service_staff':
        void navigate(routes.manageMenu, { venueId })
        break
      case 'delivery_staff':
        void navigate(routes.orders, { venueId, tab: 'ready' })
        break
      case 'user':
        /**
         * No one actually has this role, but it's here for completeness. We
         * should really remove it from `ValidRole`.
         */
        void navigate(routes.accessDenied)
        break
      default:
        assertUnreachable(role)
    }
  }, [readyToRedirect, role, venueId, userHasNoVenue])

  return <SplashScreen />
}
