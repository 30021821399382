import { AuthGuard } from 'components/AuthGuard'
import { LandingRedirect } from 'components/Landing/LandingRedirect'
import React from 'react'

const RootIndex = () => {
  return (
    <AuthGuard>
      <LandingRedirect />
    </AuthGuard>
  )
}

export default RootIndex
